import React, { useState } from 'react';
import '../styles/Navbar.css';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeLink, setActiveLink] = useState('/'); // Track active link

  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLogoClick = () => {
    navigate('/');
    setActiveLink('/'); // Set home as active when logo is clicked
  };

  const handleNavClick = (path) => {
    setActiveLink(path); // Set the clicked link as active
    setIsOpen(false); // Close the menu
  };

  return (
    <nav className="navbar">
      <img
        className="logo1"
        onClick={handleLogoClick}
        src="./slogo.png"
        alt="logo"
      />

      {/* Hamburger Menu Icon */}
      <div className="hamburger" onClick={toggleMenu}>
        ☰
      </div>

      <ul className={`nav-links ${isOpen ? 'active' : ''}`}>
        <li>
          <Link
            to="/"
            onClick={() => handleNavClick('/')}
            className={activeLink === '/' ? 'active' : ''}
          >
            Home
          </Link>
        </li>
        <li>
          <Link
            to="/about"
            onClick={() => handleNavClick('/about')}
            className={activeLink === '/about' ? 'active' : ''}
          >
            About Us
          </Link>
        </li>
        <li>
          <Link
            to="/resource"
            onClick={() => handleNavClick('/resource')}
            className={activeLink === '/resource' ? 'active' : ''}
          >
            Resources
          </Link>
        </li>
        <li>
          <Link
            to="/facilities"
            onClick={() => handleNavClick('/facilities')}
            className={activeLink === '/facilities' ? 'active' : ''}
          >
            Services
          </Link>
        </li>
        <li>
          <Link
            to="/contact"
            onClick={() => handleNavClick('/contact')}
            className={activeLink === '/contact' ? 'active' : ''}
          >
            Contact
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
