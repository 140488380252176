import React, { useState } from 'react';
import '../styles/Calculator.css';

const GSTCalculator = () => {
  const [amount, setAmount] = useState('');
  const [gstRate, setGstRate] = useState(18);  // Default GST rate to 18%
  const [result, setResult] = useState('');

  // Handle GST Calculation
  const calculateGST = () => {
    const baseAmount = parseFloat(amount);  // The pre-GST price entered by the user

    // Validate inputs
    if (isNaN(baseAmount) || baseAmount <= 0) {
      setResult('Please enter a valid amount.');
      return;
    }

    // Calculate GST amount
    const gstAmount = baseAmount * (gstRate / 100);

    // Calculate the total amount after adding GST
    const totalAmount = baseAmount + gstAmount;

    // Display the result
    setResult(`
      Pre-GST Amount: ₹${baseAmount.toFixed(2)}
      GST (${gstRate}%): ₹${gstAmount.toFixed(2)}
      Total Amount (with GST): ₹${totalAmount.toFixed(2)}
    `);
  };

  return (
    <div className="calculator">
      <h1>GST Calculator</h1>
      <div className="form-group">
        <label htmlFor="amount">Pre-GST Amount (₹):</label>
        <input
          type="number"
          id="amount"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          placeholder="Enter amount before GST"
        />
      </div>
      <div className="form-group">
        <label htmlFor="gstRate">GST Rate (%):</label>
        <select
          id="gstRate"
          value={gstRate}
          onChange={(e) => setGstRate(e.target.value)}
        >
          <option value={5}>5%</option>
          <option value={12}>12%</option>
          <option value={18}>18%</option>
          <option value={28}>28%</option>
        </select>
      </div>
      <button className="btn" onClick={calculateGST}>Calculate GST</button>
      <div className="result">{result}</div>
    </div>
  );
};

export default GSTCalculator;
