import React, { useState } from 'react';
import '../styles/Calculator.css';

const TaxCalculator = () => {
  const [income, setIncome] = useState('');
  const [deductions, setDeductions] = useState('');
  const [result, setResult] = useState('');

  // Function to calculate tax
  const calculateTax = () => {
    const totalIncome = parseFloat(income);  // User's total income
    const deductionsAmount = parseFloat(deductions);  // Deductions the user applies

    // Validate inputs
    if (isNaN(totalIncome) || isNaN(deductionsAmount) || totalIncome <= 0 || deductionsAmount < 0) {
      setResult('Please enter valid income and deductions.');
      return;
    }

    // Calculate taxable income
    const taxableIncome = totalIncome - deductionsAmount;

    // Initialize tax variable
    let tax = 0;

    // Apply tax slabs
    if (taxableIncome <= 250000) {
      tax = 0;
    } else if (taxableIncome <= 500000) {
      tax = (taxableIncome - 250000) * 0.05;
    } else if (taxableIncome <= 1000000) {
      tax = (taxableIncome - 500000) * 0.20 + 250000 * 0.05;
    } else {
      tax = (taxableIncome - 1000000) * 0.30 + 500000 * 0.20 + 250000 * 0.05;
    }

    // Display the result
    setResult(`
      Total Income: ₹${totalIncome.toFixed(2)}
      Deductions: ₹${deductionsAmount.toFixed(2)}
      Taxable Income: ₹${taxableIncome.toFixed(2)}
      Total Tax Payable: ₹${tax.toFixed(2)}
    `);
  };

  return (
    <div className="calculator">
      <h1>Tax Calculator</h1>
      <div className="form-group">
        <label htmlFor="income">Total Income (₹):</label>
        <input
          type="number"
          id="income"
          value={income}
          onChange={(e) => setIncome(e.target.value)}
          placeholder="Enter total income"
        />
      </div>
      <div className="form-group">
        <label htmlFor="deductions">Deductions (₹):</label>
        <input
          type="number"
          id="deductions"
          value={deductions}
          onChange={(e) => setDeductions(e.target.value)}
          placeholder="Enter deductions"
        />
      </div>
      <button className="btn" onClick={calculateTax}>Calculate Tax</button>
      <div className="result">{result}</div>
    </div>
  );
};

export default TaxCalculator;
