import React from 'react';
import '../styles/Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        {/* Quick Links Section */}
        <div className="footer-links">
        <img className="logo1" src="./slogo.png" alt="logo" />
          <p>
          We are committed towards providing timely, unbiased and expert guidance so that the best decisions can be possibly made.
           In support with our expert team of professionals, one gets the opportunity to set the entire life goals especially those 
           that can bestow fruitful out comings in the expected future.
          </p>
          
          
        </div>

        <div className="footer-links">
          <h4>Quick Links</h4>
          <ul>
            <li><a href="/">Home</a></li>
            <li><a href="/about">About Us</a></li>
            <li><a href="/facilities">Services</a></li>
            <li><a href="/contact">Contact</a></li>
            
          </ul>
        </div>

        {/* Address Section */}
        <div className="footer-address">
          <h4>Contact Us</h4>
          <address>
          #377/6,”SHIVAGIRI” 2nd Floor, 4th Main, 8th Cross. P.J.Extention.<br/> Davangere-577 002 Karnataka<br />
            <a href="tel:9845169191">+91-9845169191</a><br />
            <a href="tel:9902977322">+91-9902977322</a><br />
            <a href="mailto:hegde3469@gmail.com" className="contact-link">hegde3469@gmail.com</a>
          </address>
        </div>
      </div>

      {/* Copyright Section */}
      <div className="footer-bottom">
        <p>&copy; 2024 Hegde FP. All rights reserved.</p>
        <p>Design And Developed by :<a href="https://www.tackled.co.in/" style={{
      color: "yellow",
      textDecoration: "none",
      fontWeight: "bold",
    }}> Tackle-D</a> </p>
      </div>
    </footer>
  );
};

export default Footer;
