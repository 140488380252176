import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Features from './components/Features';
import Footer from './components/Footer';
import AboutUs from './components/AboutUs';
import Facilities from './components/Facilities';
import ContactUs from './components/ContactUs';
import ResourcesPage from './components/Resource';


const App = () => {
  return (
    <Router>
    <div>
      <Navbar />
      <Routes>
      <Route path="/" element={<> <Hero /> <Features /> </>} />
      
      <Route path="/about" element={<AboutUs />} />
      <Route path="/facilities" element={<Facilities />} />
      <Route path="/resource" exact element={<ResourcesPage/>} />
      
      <Route path="/contact" element={<ContactUs/>} />
      
      </Routes>
      <Footer />
    </div>
    </Router>
  );
};

export default App;
