// Menu data

const menus = {
  "Consultation▼ ": {
    
      "SIP Investments":[ <h1>SIP Investments</h1>,
        <div>
        <p>SIP, also known as Regular Savings Plan (RSP) in some countries, allows you to invest a fixed amount
           at pre-defined frequencies in mutual funds. A bank or post office recurring deposit is the only other
            investment option that is similar to SIP. There are basically two options that an investor could take
            when they are making investments, one would be to invest lump sum into mutual funds and the other would
             be to invest using an SIP.</p><br/>
        <h3>Systematic Investment Plan Benefits</h3><br/>
        <ul>
          <li>Get financial discipline to life</li>
          <li>Start Investing with a small amount</li>
          <li>Stay invested irrespective of market conditions</li>
          <li>Cost averaging brings down purchase cost</li>
          <li>Enhance possibilities of better returns</li>
        </ul><br/>
        
        <h3>Our Investment Offers</h3><br/>
        <ul>
          <li>Get financial discipline to life</li>
          <li>Start Investing with a small amount</li>
          <li>Stay invested irrespective of market conditions</li>
          <li>Cost averaging brings down purchase cost</li>
          <li>Enhance possibilities of better returns</li>
        </ul>
        </div>
      ],
          // Systematic Investment Plan BenefitsGet financial discipline to life Start Investing with a small amount Stay invested irrespective of market conditions Cost averaging brings down purchase cost Enhance possibilities of better returns Our Investment Offers Get financial discipline to life Start Investing with a small amount Stay invested irrespective of market conditions Cost averaging brings down purchase cost Enhance possibilities of better returns"],

    
    "Estate Planning.": [<h1>Estate palning</h1>,
      <div>
      <p >An estate is considered to be as the total property, whether real and personal owned by an individual prior the distribution of trust or will. The distribution of both the real and personal property among its prospective heirs comes inside an estate planning. The aim to preserve the maximum amount of wealth during the possibility of an intended beneficiaries and flexibility towards the prior death of an individual comes under estate planning.</p><br/>

      <p>Your “estate” is an inclusive of all property owned by you at the time of your uncertain death. Your bank accounts, life insurance policies, real estate, personal property and even your other securities ,all are treated as your valued estate.</p><br/>
      
      <p>No one likes to think about their own mortality; however Estate Planning is one of your most important financial obligations. We believe that the wealth that you work a lifetime to create should be protected. For this reason, we offer Estate Planning strategies to provide our clients with the comfort and peace of mind given an unfortunate event.</p><br/>
      
      <p>Who does not want that their loved one would remain financial feasible even after your death? To make this possible even in your absence, the implementation of estate planning is definite to make. All the existing legal questions related to your financial status are actively solved by a well qualified estate planner. An experienced estate planning attorney of our well established organization can recognize you with all the options which are available to meet the basic estate goals of yours and your loved ones in mind.</p><br/>
      
      <p>Understanding of the right estate planning option turns out to be absolutely feasible in solving your complex financial worries, as the availability of resources available promises in uplifting you and your family financial status to extreme heights.</p></div>],
      
    "Retirement Planning": [<h1>Retirement Planning</h1>,
      <div >
      <p >In today’s fast moving world, planning towards your retirement can no longer take a back seat. If planned carefully, you can make your retirement period as glorious as your present employed career. A successful planning towards retirement can in no way hit hard on your financial status, as the right planning can ensure you with the same lifestyle till the time you live.</p><br/>

      <p>Retire with dignity, sounds great in itself. Ensure that the time when you retire you should be liable in carrying enough funds, which prospers in meeting with your expenses by maintaining the same established life style. Just do not let your financial worries spoil your precious retirement days. Initiation of savings habits today in your financial career can help you to realise your dreams after your retirement.</p><br/>
      
      <p>As soon as many of us reach the stage of retirement, the disability in our financial status becomes a major area of concern. During this period, the income generally stops but the expenses do not. In order to realise your dreams of retirement, we help you every step of the way in making a successful planning for you.</p><br/>
      
      <p>How crucial is retirement planning for you? What time appears to be best for you to invest? How planning in advance turns out to be fruitful for your coming years? Our experts can help you with the best solutions to the above questions and any other that may be of concern to you</p><br/>
      
      <p>Choose excellent retirement planning related services from us and let your money grow for your previous retirement years.</p>
      </div>],
      
    "Income Protection": [<h1>Income Protection</h1>,
      <div>
        <p>As a only sole bread winner in your family, unfortunately, due to any reasons if you have lost the vigour and strength to work then no need to worry. As long as you are joined with us, we can assure you in granting financial support not only you but to your entire family through income protection services. Under income protection program, you get a chance to remain financially secure without even indulging in any of the debts. If not then again maintaining or involving in other mortgage would turn out to be more stressful than the ability to earn.</p><br/>
        <p>Income protection shoulders you when the existing dependent on your savings would no longer turn out to be feasible for you. The payments continue until the employee becomes able to return to work or reaches the retirement age. Benefits offered by us through income protection plans include:-</p><br/>
        <ul>
          <li>Death benefit</li>
          <li>Flexible waiting periods</li>
          <li>Total disability Benefits</li>
          <li>Partial disability Benefit</li>
          <li>Specific Injury Benefit</li>
        </ul>
        </div>],

    "Fixed Deposits.": [<h1>Fixed Deposits</h1>,
      <div style={{padding:"10px"}}>
        <p>Fixed income securities are crucial part of an investment Portfolio. Fixed deposits represent investments
           that provide income largely in the form of fixed periodic payments and repayment of the increased amount at
            the time of maturity. Along with this,
           it helps in increasing your returns without even exceeding your risks.</p><br/>
        <h4>Eligibility and documentation required:</h4><br/>
        <p>Here we have detailed some facts for you, which your relationship manager might hide from you:</p><br/>
        <ol >
          <li>Akin to banks, companies also offer fixed deposits</li>
          <li> Bank deposits are more secured than company fixed deposits</li>
          <li>Avoid involving with those who promises to offer you high rate of interest</li>
          <li>Higher interest frequency not only guarantees higher returns every time</li>
          <li>Due to any reasons if you withdraw prematurely, your deposits will bring lower than promised returns</li>
          <li>Penalty as well as premature withdrawals can be negotiated</li>
          <li>Interest on fixed deposits will be charged before it is paid out to you</li>
          <li>Not all 5 years deposits guarantee you to enjoy section 80C income tax exemption</li>
          <li>You can raise a loan or overdraft facility against your FD</li>
          <li> Sweep- in excess cash deposit facility from your savings account into fixed deposits</li>
        </ol>

      </div>],

    "Investment Planning": [<h1>Investment Planning</h1>,
      <div>
        <p>Saving your hard earned money for the betterment of your financial career is like half the battle won.
           Many a times, you must have heard about investment planning and its key benefits towards the maintenance 
           of bright future. Essentially, investment planning focuses on helping you in deciding the right investment strategy.
          Investment planning allows your funds status to generate more income by teaching you the necessary way outs of wealth
           enhancement through actively concentrating on your existing investment portfolio.</p><br/>
        <p>A careful planning before investment can fulfil your dreams towards a risk free financial security. 
          Many of us are not aware with the fact that efficient planning of the investments ahead offers threefold benefit, 
          which includes savings on tax, building a strong financial portfolio and lastly an excellent way to enhance your 
          future funds.</p><br/>
        <p>In order to best satisfy your overall investment needs, we work with extreme flexibility by largely focusing on 
          the major risk areas or those, which have the potential to ruin your financial freedom. Our services help you 
          in supporting entire life long goals that largely fulfills the basic goals. We offer wide variety of investment 
          choices for financial relief onlookers; Bonds, mutual funds, real estate, stocks along with finest insurance 
          protection and retirement savings, you will find our peerless and superior investment planning services to be the best.</p><br/>
        <p>Our investment planning steps undergo all those stages, which make your investment planning successful. 
          Identification of needs, analyzing about your investment choices and the final implementation on various 
          investment choices are the fixed investment planning processes through which we solve the worries of on-lookers.</p><br/>
      </div>],
    "Risk Management": [<h1>Risk Management</h1>,
      <div>
        <p>A multitude of risks exist in today’s business environment. We pride ourselves in our proven ability to
           accurately assess risk and protect you from vulnerabilities inherent in your enterprise. An effective risk
            management strategy improves the performance of your business, enables you to complete goals, and protects
             the value of your business.</p><br/>
        <h4>Our risk assessment includes</h4><br/>
        <ul>
          <li>Assembling relevant information about your business and market</li>
          <li>Identifying the major risks facing your organization</li>
          <li>Developing a risk strategy</li>
          <li>Implementing the risk management procedures</li>
        </ul>
      </div>],
      
    "Insurance Planning": [<h1>Insurance Planning</h1>,
      <div>
        <h4>IF YOU WANT TO PROTECT YOUR WEALTH, PROPER INSURANCE PLANNING IS A MUST.</h4><br/>
        <p>Covering yourself under the umbrella of insurance is extremely important especially for breadwinners who 
          are supporting their families alone. Insurance planning is primarily concerned with ensuring in covering against 
          all the risks, which remain prevalent in our routine lives. The planning of insurance is an important attribute of 
          life, which cannot be ignored at any cost.</p><br/>
        <p>Whether it is concerned with buying a dream house, accumulation of wealth, pay check or even covering basic human needs,
           the role of a successful insurance planning cannot be ignored. You must not believe that proper insurance planning 
           can allow you to receive the same benefits by charging you lesser premium than before.</p><br/>
        <p>Bear in mind, insurance is an important risk management tool that can protect you and your family from financial
           hardship caused by unplanned events. In order to make this whole process more affluent,we work with our clients 
            to identify your risks and implement cost-effective risk management program that has been developed with your 
            specific circumstances and requirements in mind. Insurance recommendations provided by our </p><br/>
        <p>Insurance Planning Services include:</p><br/>
        <ul>
          <li>Life Insurance</li>
          <li>Health Insurance</li>
          <li>Personal Accident Insurance</li>
          <li>Income Protection Insurance</li>
          <li>Child Cover</li>
          <li>Asset Protection Insurances</li>
        </ul>
        <p>Under the guidance of our sharp minded employees, you can get an opportunity to receive wider insurance coverage 
          and interestingly at low premium rates. Consideration of your overall needs, deciding what type of life insurance
           can best meet with your needs and other service related queries are actively answered by our efficient team.</p><br/>
        <p>A well planned insurance policy of ours can help you out in obtaining excellent funds security of both life and asset in mind. Along with this, if you are suffering by paying more than half of your profits on taxation then do not worry at all as some of our insurance planning services facilities allow you to eliminate tax related expenses greatly.</p><br/>
        <p>Contact us immediately!</p><br/>
      </div>],

    "Child Future Planning": [<h1>Child Future Planning</h1>,
      <div>
        <p>Planning for your child’s future is a much common concern, which continues to exist in the hearts of every parent. Parents in no way want their children with the same pains, hardships, shortage of money and resources, which were once experienced by them. None of the other joys carry enough potential to please parents than seeing their own children growing not only physically but financially also. As a parent if you have started experiencing the hindrances in relation to the development, education and marriage of your children then you are surely in need of children’s future planning.</p><br/>
        <p>The primary purpose of children’s future planning is to develop accurate funds needs by drawing a guide map of all foreseeable expenditures including higher education and several others, which might arise in the coming years.</p><br/>
        <p>Furthermore, in today’s hectic world, where inflation is already hitting hard and saving as well as investing for your child’s future is getting difficult, none but alone children’s future planning serves as a perfect destination, if you are eagerly looking forward to making strong and feasible financial career. If the cost of your child’s college fees is raising your fears, then this planning is crucial for you.</p><br/>
        <p>Children’s future planning services offerings with us overcome almost all of your worries as we take whole responsibilities on our shoulders. We keep you enlightened with those investment techniques, which can cover your financial blanket at the deepest level.</p><br/>
        <p>Along with this, we teach your children the value of money. Being included under our children’s future planning services, you can receive excessive freedom towards the overall management of money as it is never too late to start planning for your children’s future. Under successful planning scheme, we work towards the betterment of feasible and stable career of your children.</p><br/>
      </div>],

    "NRI Investments": [<h1>NRI Investments</h1>,
      <div>
        <p>There are certainly attractive avenues in India, where NRIs obtain the freedom of investing into diversified investment portfolio and significantly build up their investment portfolio. India is emerging as being fastest growing investment destinations for NRIs.</p><br/>
        <h4>NRIs can now invest into investment vehicles by saving into:</h4><br/>
        <ul>
          <li>Bank Deposits</li>
          <li>Secondary markets through Portfolio investment in equity shares/convertible debentures.</li>
          <li>New issues (shares/convertible debentures).</li>
          <li>Non convertible debentures.</li>
          <li>Mutual funds provided that amount is invested out of NRE/FCNR/NRO account or by inward remittance.</li>
          <li>Domestic (NRO) funds through deposits in Indian companies (including Non Banking Finance Companies if they are registered with Reserve Bank of India) on non repatriation basis upto 3 years subject to certain formalities to be completed by the concerned company.</li>
          <li>Bonds provided that amount is invested out of NRE/FCNR/NRO account or by inward remittance.</li>
          <li>Proprietary or partnership concern in India.</li>
          <li>Immovable property provided that the amount is not invested for the purchase of agricultural land, plantation property or farm house and investments are made from fresh inward remittance or existing non resident account.</li>
        </ul><br/>
        <h4>Tax benefits available to NRIs</h4><br/>
        <ul>
          <li>Bank Deposits investment in shares, units of Mutual Funds etc. are exempt from wealth tax in India.</li>
          <li>In 1997, gift tax was abolished. So both the donor as well as the recipient did not have to pay any tax on the gifts received. Consequently people started misusing the vacuum left behind by scrapping of gift tax. There was a widespread transfer of insincere gifts from the non-relatives. In order to fill up this void, Section 56 (2)(v) of Income Tax Act was passed in 2004.As per Section 56 (2)(v) of the Income Tax Act , any amount exceeding Rs 25,000 obtained by a person or a Hindu Undivided Family (HUF) without any consideration from non-relative would be taxed. The only cases exempted were the gifts given during marriage, inheritance left behind in a will or if the payer has died.</li>
        </ul>
      </div>],
  },
  "Distribution▼ ": {
    "Mutual Funds": [
      <h1>Mutual Funds</h1>,
      <div>
        <p>Now a days, mutual fund is gaining its popularity, with the emphasis on increase in domestic savings and improvement
           in deployment of investment through markets, the need and scope for mutual fund operation has increased tremendously.
            An ordinary investor who applies for share in a public issue of any company is not assured of any firm allotment.
             But mutual funds who subscribe to the capital issue made by companies get firm allotment of shares. 
             Mutual fund latter sell these shares in the same market and to the Promoters of the company at a much higher price. 
             Hence, mutual fund creates the investors confidence.</p><br/>
            
        <h4>Working of the Fund House </h4><br/>
        <h4>The mutual fund universe can be divided into six basic styles: </h4><br/>
        <ol>
          <li> Small cap growth funds</li>
          <li>Large cap growth funds</li>
          <li> Small cap value</li>
          <li>Large cap value</li>
          <li> Foreign funds</li>
          <li>Fixed income funds</li>
        </ol>
       
      </div>
    ],
    "Life Insurance":  [
      <h1>Life Insurance</h1>,
      <div>
        <p>Life insurance is a type of insurance policy that provides financial protection to the insured person's family
           or beneficiaries in the event of their death. It ensures that loved ones receive a lump sum or periodic payments
            (known as the death benefit) to help replace the insured's income, settle debts, or meet other financial obligations.</p>
            <br/>
          <h4>Key Features of Life Insurance:</h4><br/>
          <ol>
            <li>Protection Against Uncertainty: It acts as a financial safety net for the insured's dependents.</li>
            <li>Premium Payment: The insured pays a premium (either periodically or as a lump sum) for the coverage.</li>
            <li>Death Benefit: A guaranteed payout is made to the beneficiaries if the insured passes away during the policy term.</li>
            <li>Savings and Investment Option: Some life insurance policies also include a savings or investment component, providing returns or maturity benefits if the policyholder survives the term.</li>
            <li>Tax Benefits: Premiums paid and payouts often enjoy tax exemptions under specific regulations in many countries.</li>
          </ol><br/>
          <h4>Types of Life Insurance:</h4><br/>
            <ol>
              <li> Term Life Insurance:
                Provides coverage for a specific term (e.g., 10, 20, or 30 years).
                If the insured passes away during the term, the beneficiaries receive the death benefit.
                Does not offer maturity or survival benefits. </li>
              <li>Whole Life Insurance:
                  Offers coverage for the insured's entire life.
                  Includes a death benefit and may accumulate cash value over time.</li>
              <li> Endowment Plans:
Provides life cover and a savings component.
Pays out a lump sum upon death or survival at the end of the policy term.</li>
              <li> Unit-Linked Insurance Plans (ULIPs):
Combines life insurance with investment opportunities.
Part of the premium is allocated to insurance, while the remainder is invested in market-linked funds.</li>
              <li> Money-Back Policies:
Periodically returns a portion of the sum assured during the policy term.
Provides a death benefit if the insured passes away before the term ends.</li>
              <li> Child Plans:
Designed to secure a child's future education or financial needs.
Provides payouts at key milestones or upon the policyholder's death.</li>
              <li> Retirement Plans:
Provides financial stability during retirement.
Offers a lump sum or regular income after the policyholder retires.</li>
              
            </ol><br/>
            <h4>Benefits of Life Insurance:</h4><br/>
            <ul>
              <li>Financial Security: Provides income replacement for the insured's family.</li>
              <li>Savings Component: Some policies act as a tool for long-term savings or investments.</li>
              <li>Debt Protection: Ensures loans or liabilities are settled in case of the insured's death.</li>
              <li>Tax Advantages: Offers tax-saving benefits under applicable laws.</li>
            </ul><br/>

          <h4>How Life insurance works?</h4><br/>
          <ul>
            <li>Choose a Policy: Decide on the type of coverage, sum assured, and policy term based on financial goals.</li>
            <li>Pay Premiums: Regular premiums or a lump sum are paid to the insurer.</li>
            <li>Coverage Period: During this period, the insured is protected.</li>
            <li>Claim Settlement: In case of the insured's death, the beneficiaries receive the death benefit.</li>
          </ul>

      </div>
    ],
    "Health Insurance": [
      <h1>Health Insurance</h1>,
      <div>
        <p>Medical care has become an expensive affair today. Instant catering to huge hospital bills may not be probable for all. With big diseases like cancer, cardiac problems, severe joint problems, etc. becoming common, it has become difficult for the common masses to bear the expenses at ease.</p><br/>
        <p>As per a survey, less than 10% of the population of India has the ability to pay Rs. 5 lakh or less in case of a surgical emergency. Mediclaim is the best answer in such a situation. With a mediclaim, an individual or a family can lead a stress-free and worry-free life and any inconveniences related to health will be taken care of by the insurer.</p><br/>
        
        <h4>Many people are still most comfortable purchasing from an agent. Are you one of them? Then have a look at this guide:</h4><br/>
        <ul>
          <li>Always choose a reputed health or medical insurance provider in order to reduce the probability of facing problems in service or claims.</li>
          <li>Listen to your agent carefully to know about the product’s specifications. In particular, understand the exclusions and co-deductibles.</li>
          <li>Another question to ask your agent: Is the claims procedure simple? What are the claims statistics of the provider?</li>
          <li>Don’t hesitate to ask your agent any question regarding your policy or provider…it is important to be completely clear on it from the start.</li>
          <li>Final point to keep in mind once you buy your health insurance plan: be sure to collect the receipt of the payment made – it is your right!</li>
        </ul>
      </div>
    ],
    "Geneal Insurance": [
      <h1>General Insurance</h1>,
      <div>
        <p>General insurance refers to non-life insurance policies that provide coverage for risks and losses other than life,
           such as those related to property, health, travel, liability, and accidents. These policies help protect individuals,
            businesses, and assets from financial losses due to unexpected events. Here's a breakdown of its key features and types:</p>
            <br/>
          <h4>Key Features of General Insurance:</h4><br/>
          <ol>
            <li>Risk Coverage: It offers protection against unforeseen events like accidents, natural disasters, theft, or liability claims.</li>
            <li>Indemnity-Based: Most general insurance policies are indemnity-based, meaning they compensate for actual financial losses up to the policy limit.</li>
            <li>Fixed Tenure: General insurance policies usually have a fixed duration (e.g., one year), after which they need renewal.</li>
            <li>Premium Payment: The insured pays a premium to the insurer, calculated based on the risk level and coverage.</li>
            <li>No Maturity Benefit: Unlike life insurance, general insurance does not offer payouts upon policy maturity.</li>
          </ol><br/>
          <h4>Types of General Insurance:</h4><br/>
            <ol>
              <li> Health Insurance:
                Covers medical expenses, hospitalization costs, surgeries, and sometimes preventive healthcare.
                  Can include additional benefits like critical illness cover or maternity benefits.</li>
              <li>Motor Insurance:
                  Third-Party Liability: Covers damages or injuries caused to others.
                  Comprehensive Coverage: Includes third-party liability and damages to the insured's vehicle.</li>
              <li>Home Insurance:
              Protects homes and belongings from risks like fire, theft, natural disasters, or vandalism.</li>
              <li>Travel Insurance:
              Covers risks during travel, including trip cancellations, medical emergencies, lost baggage, and flight delays.</li>
              <li>Fire Insurance:
              Provides compensation for damages caused by fire, explosions, or related perils.</li>
              <li> Liability Insurance:
              Protects businesses or individuals from legal claims, such as professional errors, negligence, or public liabilities.</li>
              <li>Marine Insurance:
              Covers cargo, ships, or goods in transit against risks like theft, damage, or accidents.</li>
              <li> Personal Accident Insurance:
              Covers accidental injuries, disability, or death of the insured.</li>
            </ol><br/>
            <h4>Benefits of General Insurance:</h4><br/>
            <ul>
              <li>Financial Security: Reduces financial stress caused by unexpected events.</li>
              <li>Legal Compliance: Some insurances, like motor third-party liability, are legally mandatory in many countries.</li>
              <li>Peace of Mind: Knowing you're covered helps reduce anxiety about potential risks.</li>
              <li>Customizable Coverage: Policies can often be tailored to suit specific needs and risks.</li>
            </ul>

      </div>
    ],
  
  }
};

export default menus;