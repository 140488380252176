import SIPCalculator from "./pages/SIPCalculator";
import EMICalculator from "./pages/EmiCalculator";
import LumpsumCalculator from "./pages/LumpsumCalculator";
import ChildEducationCalculator from "./pages/ChildEducation";
import ChildMarriageCalculator from "./pages/Childmarriage";
import RetirementCalculator from "./pages/Retirement";
import TaxCalculator from "./pages/TaxCalculator";
import GSTCalculator from "./pages/GstCalculator";

const resources = {
    "Calculators▼": {
      "SIP Calculator":<SIPCalculator/>,
      "EMI Calculator":<EMICalculator/>,
      "Lumpsum Calculator":<LumpsumCalculator/>,
      "Child Education":<ChildEducationCalculator/>,
      "Child Marriage Planning":<ChildMarriageCalculator/>,
      "Retirement Planning":<RetirementCalculator/>,
      "Tax Calculators":<TaxCalculator/>,
      "GST Calculator":<GSTCalculator/>,
    },
      "Tax Facts▼":(
        <div>
          <p>We thought the following information on various tax issues would be useful to you. Personal circumstances always vary, so please ensure you contact us for specific advice.</p><br/>
          <ul>
          <li><a href="https://www.incometax.gov.in/iec/foportal/">	Income tax</a></li>
          <li><a href="https://incometaxindia.gov.in/Charts%20%20Tables/Tax%20rates.htm">	Income Tax Rates</a></li>
          <li><a href="https://incometaxindia.gov.in/Tutorials/15-%20LTCG.pdf">	Capital gains and assets</a></li>
          <li><a href="https://taxguru.in/income-tax/exemptions-deduction-new-tax-regime-fy-2023-24-ay-2024-25.html">	Deductions</a></li>
          <li><a href="https://www.onlineservices.nsdl.com/paam/endUserRegisterContact.html">	Permanent Account Number – PAN</a></li>
          <li><a href="https://incometaxindia.gov.in/Communications/Circular/Circular2_2018.pdf">	Income Tax Act</a></li>

          </ul>
        </div>
      ),

      
    
    "Useful Links▼": {
      "GST-Goods and Services Tax":[
        <div>
        <p>Goods and Services tax (GST) has been identified as one of most important tax reform in India. For more knowledge, please check below links</p><br/>
        <ul>
          <li><a href="https://www.rbi.org.in/">	Reserve Bank of India</a></li>
          <li><a href="https://www.mca.gov.in/content/mca/global/en/home.html" >	Ministry of Corporate Affairs</a></li>
          <li><a href="https://finmin.gov.in/">	Ministry of Finance</a></li>
          <li><a href="https://nipfp.org.in/home-page/">	Insurance Regulatory Authority of India</a></li>
          <li><a href="https://www.rbi.org.in/">	NIPFP DEA Research Program on Capital Flows</a></li>
          <li><a href="https://www.nism.ac.in/">	National Institute of Securities Market</a></li>
          <li><a href="https://investor.sebi.gov.in/">	SEBI Investor Awareness Website</a></li>
          <li><a href="https://www.nism.ac.in/">	Electronic Data Information Filing and Retrieval System (EDIFAR)</a></li>
          <li><a href="https://www.nism.ac.in/">Corporate Filing and Dissemination System</a></li>
        </ul><br/>
        <p> Note : The websites links provided on this page are for general interest only. We do not endorse any products and services offered
         by the websites listed on this page.</p><br/>
        </div>],
        "Stock Exchanges in India":[
        <div>
       <p>Please Note: Trying to find a high quality, useful site on the web can often be a time-consuming experience. To save you the trouble we’ve compiled a list of websites that we’ve
         found to be valuable sources of information. Clicking on a link will open a new window for you.</p><br/>
        <ul>
          <li><a href="https://www.nseindia.com/">	National Stock Exchange</a></li>
          <li><a href="https://www.nseindia.com/" >	The Stock Exchange, Mumbai</a></li>
          <li><a href="https://www.acml.in/">	Ahmedabad Stock Exchange</a></li>
          <li><a href="https://www.cse-india.com/">	Calcutta Stock Exchange</a></li>
          <li><a href="https://www.cochinstockexchange.com/">		Cochin Stock Exchange Limited</a></li>
          <li><a href="http://www.coimbatore.com/csx/csx.htm">	Coimbatore Stock Exchange Limited</a></li>
          <li><a href="https://www.dseindia.org.in/">	Delhi Stock Exchange</a></li>
          <li><a href="http://www.iseindia.com/">	Inter-connected Stock Exchange of India Ltd.</a></li>
          <li><a href="http://www.otcei.net/">	OTC Exchange of India</a></li>
          <li><a href="https://sksesl.com/">	Saurashtra Kutch Stock Exchange Limited</a></li>
          <li><a href="https://www.mcxindia.com/">	MCX Stock Exchange</a></li>
        </ul><br/>
        <p> Note : The websites links provided on this page are for general interest only. We do not endorse any products and services offered
         by the websites listed on this page.</p><br/>
        </div>],
        "Market Regulators Worldwide":[
        <div>
          <p>Please Note: Trying to find a high quality, useful site on the web can often be a time-consuming experience. To save you the trouble we’ve compiled a list of websites that we’ve
          found to be valuable sources of information. Clicking on a link will open a new window for you.</p><br/>
       <h4>Asia</h4><br/>
        <ul>
          <li><a href="https://www.spk.gov.tr/">	Capital Market Board (Turkey)</a></li>
          <li><a href="https://www.mof.go.jp/" >Securities Bureau of the Ministry of Finance (Japan)</a></li>
          <li><a href="https://www.sc.com.my/">	Securities Commission (Malaysia)</a></li>
          <li><a href="https://sec.gov.bd/">	Securities and Exchange Commission (Bangladesh)</a></li>
          <li><a href="https://www.hksfc.org.hk/en/">	Securities and Futures Commission (Hong Kong)</a></li>
          <li><a href="https://www.secp.gov.pk/">	Securities and Exchange Commission of Pakistan</a></li>
          
        </ul><br/>

        <h4>Europe</h4><br/>
        <ul>
          <li><a href="https://www.spk.gov.tr/">	BAWe – Bundesaufsichtsamt für den Wertpapierhandel (Germany)</a></li>
          <li><a href="https://www.centralbank.cy/" >Central Bank of Cyprus</a></li>
          <li><a href="https://www.cnmv.es/portal/home.aspx">	CNMV – Comisión Nacional del Mercado de Valores (Spain)</a></li>
          <li><a href="https://www.consob.it/web/consob-and-its-activities">	CONSOB Commissione Nazionale per le Società e la Borsa (Italy)</a></li>
          <li><a href="https://www.economie.gouv.fr/saef/commission-des-operations-bourse-cob">	COB – Comission des Opérations de Bourse (France)</a></li>
          <li><a href="https://www.gov.je/Government/Departments/Economy/Pages/FinancialServicesUnit.aspx">		Financial Services Department (Jersey)</a></li>
          
        </ul><br/>

        <h4>Latin America</h4><br/>
        <ul>
          <li><a href="https://www.gob.mx/cnbv">		CNBV – Comisión Nacional Bancária y de Valores (Mexico)</a></li>
          <li><a href="https://www.argentina.gob.ar/cnv/" >CNV – Comisión Nacional de Valores (Argentina)</a></li>
          <li><a href="https://www.gob.pe/smv">	CONASEV – Comisión Nacional Supervisora de Seguros y Valores (Peru)</a></li>
          <li><a href="https://globaledge.msu.edu/global-resources/resource/10626">	SVS – Superintendencia de Valores y Seguros (Chile)</a></li>
          <li><a href="https://www.superfinanciera.gov.co/">	Superintendencia de Valores (Colombia)</a></li>
          
        </ul><br/>
        <h4>Australia</h4><br/>
        <ul>
          <li><a href="https://asic.gov.au/">Australian Securities Commission</a></li>
          <li><a href="https://www.fma.govt.nz/" >The New Zealand Securities Commission</a></li>
          
        </ul><br/>

        <h4>North America</h4><br/>
        <ul>
          <li><a href="https://www.gob.mx/cnbv">		CNBV – Comisión Nacional Bancária y de Valores (Mexico)</a></li>
          <li><a href="https://www.argentina.gob.ar/cnv/" >CNV – Comisión Nacional de Valores (Argentina)</a></li>
          <li><a href="https://www.gob.pe/smv">	CONASEV – Comisión Nacional Supervisora de Seguros y Valores (Peru)</a></li>
          <li><a href="https://globaledge.msu.edu/global-resources/resource/10626">	SVS – Superintendencia de Valores y Seguros (Chile)</a></li>
          
        </ul><br/>

        
        <h4>Others</h4><br/>
        <ul>
          <li><a href="https://www.iadb.org/en">	BID – Banco Interamericano de Desarollo </a></li>
          <li><a href="https://www.bis.org/" >BIS – Bank for International Settlements </a></li>
          <li><a href="https://www.gov.br/cvm/pt-br">	COSRA – Council of Securities Regulators of the Americas </a></li>
          <li><a href="https://www.fasb.org/">		FASB – Financial Accounting Standards Board </a></li>
          <li><a href="https://www.fiabv.org/">	FIABV – Federación Iberoamericana de Bolsas de Valores </a></li>
          <li><a href="http://www.fibv.com/">	FIBV – Federation Internationale des Bourses de Valeurs  </a></li>
          <li><a href="https://www.ici.org/">	ICI – Investment Company Institute  </a></li>
          <li><a href="https://www.ifc.org/en/home">	IFC – International Finance Corporation </a></li>
          <li><a href="https://www.worldbank.org/ext/en/home">	World Bank   </a></li>
          <li><a href="https://www.wto.org/">	WTO – World Trade Organization  </a></li>
          <li><a href="https://anna-web.org/">	Association of National Numbering Agency </a></li>
          <li><a href="https://www.iosco.org/">	International Organization of Securities Commissions </a></li>
        </ul><br/>
       <p> Note : The websites links provided on this page are for general interest only. We do not endorse any products and services offered
         by the websites listed on this page.</p><br/>
        </div>]
    },
    
      "GST Details▼":(
        <div>
          <p>Good & Service Tax (GST) introduced in India with effective from July 01, 2017 and brings ease to do business. It provides great benefit for traders & consumers.</p><br/>
          <p>Reduction in multiplicity of taxes, more efficient neutralization of taxes especially for exports & development of common national market.</p><br/>
          <p>On the other side it equally beneficial for consumers as a simpler Tax system, reduction in prices of goods & services due to elimination of cascading & increase in employment opportunities.</p><br/>
        
          <ul>
          <li><a href="https://www.iadb.org/en">	Overview on GST </a></li>
          <li><a href="https://services.gst.gov.in/services/quicklinks/registration" >GST Registration </a></li>
          <li><a href="https://www.gov.br/cvm/pt-br">	Step By Step Guide To Taxpayers </a></li>
          <li><a href="https://www.fasb.org/">		GST Rules </a></li>
          <li><a href="https://www.fiabv.org/">	Filing of GST return </a></li>
          <li><a href="http://www.fibv.com/">	GST – CONCEPT & STATUS  </a></li>
          <li><a href="https://www.ici.org/">	Goods Rates Booklet  </a></li>
          <li><a href="https://www.ifc.org/en/home">	Service Rates Booklets </a></li>
          <li><a href="https://www.worldbank.org/ext/en/home">	GST Offline Tool   </a></li>
          <li><a href="https://www.wto.org/">	FAQ on GST  </a></li>
          <li><a href="https://anna-web.org/">Govt RBI can act as GST payment aggregator </a></li>
          <li><a href="https://www.iosco.org/">	India Inc Will Get Tax Refunds to Ensure Fair Start to GST </a></li>
          <li><a href="https://www.ifc.org/en/home">	Stakeholders View on 3 GST Reports Sought by Oct End </a></li>
          <li><a href="https://www.worldbank.org/ext/en/home">	The Central Goods And Services Tax Bill, 2017   </a></li>
          <li><a href="https://www.wto.org/">	The Goods And Services Tax (Compensation To States) Bill, 2017  </a></li>
          <li><a href="https://anna-web.org/">	The Integrated Goods And Services Tax Bill, 2017 </a></li>
          <li><a href="https://www.iosco.org/">	The Union Territory Goods And Services Tax Bill, 2017
          </a></li>
        </ul><br/>
        <h4>For more details about GST Updates, please refer below sites</h4><br/>
        <ul>
        <li><a href="https://www.gst.gov.in/">	GST – Goods and Services Tax </a></li>
        <li><a href="https://www.iosco.org/">	Central Board of Excise and Customs</a></li>
        </ul>
        </div>
      ),
    
  };
  
  export default resources;