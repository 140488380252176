import React, { useState } from 'react';
import '../styles/Calculator.css';

const EMICalculator = () => {
  const [loanAmount, setLoanAmount] = useState('');
  const [annualInterestRate, setAnnualInterestRate] = useState('');
  const [loanTenure, setLoanTenure] = useState('');
  const [emi, setEmi] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // Log for debugging
  console.log(loanAmount, annualInterestRate, loanTenure);

  const calculateEMI = () => {
    const P = parseFloat(loanAmount); // Principal loan amount
    const r = parseFloat(annualInterestRate) / 100 / 12; // Monthly interest rate
    const n = parseFloat(loanTenure) * 12; // Loan tenure in months

    console.log('P:', P, 'r:', r, 'n:', n);

    // Check for invalid inputs
    if (isNaN(P) || isNaN(r) || isNaN(n) || P <= 0 || r <= 0 || n <= 0) {
      setErrorMessage('Please enter valid inputs.');
      setEmi('');
      return;
    }

    // EMI formula
    const emiAmount = (P * r * Math.pow(1 + r, n)) / (Math.pow(1 + r, n) - 1);
    setEmi(emiAmount.toFixed(2));
    setErrorMessage('');
  };

  return (
    <div className="calculator">
      <h1>EMI Calculator</h1>
      <div className="form-group">
        <label htmlFor="loanAmount">Loan Amount (₹):</label>
        <input
          type="number"
          id="loanAmount"
          value={loanAmount}
          onChange={(e) => setLoanAmount(e.target.value)}
          placeholder="Enter loan amount"
        />
      </div>
      <div className="form-group">
        <label htmlFor="annualInterestRate">Annual Interest Rate (%):</label>
        <input
          type="number"
          id="annualInterestRate"
          value={annualInterestRate}
          onChange={(e) => setAnnualInterestRate(e.target.value)}
          placeholder="Enter annual interest rate"
        />
      </div>
      <div className="form-group">
        <label htmlFor="loanTenure">Loan Tenure (years):</label>
        <input
          type="number"
          id="loanTenure"
          value={loanTenure}
          onChange={(e) => setLoanTenure(e.target.value)}
          placeholder="Enter loan tenure in years"
        />
      </div>
      <button className="btn" onClick={calculateEMI}>Calculate EMI</button>
      
      {/* Error Message */}
      {errorMessage && <div className="error-message">{errorMessage}</div>}

      {/* Display EMI result */}
      {emi && <div className="result">EMI: ₹{emi}</div>}
    </div>
  );
};

export default EMICalculator;
