import React, { useState } from 'react';
import '../styles/Calculator.css';

const RetirementCalculator = () => {
  const [currentExpenses, setCurrentExpenses] = useState('');
  const [inflationRate, setInflationRate] = useState('');
  const [yearsToRetirement, setYearsToRetirement] = useState('');
  const [monthlyInvestment, setMonthlyInvestment] = useState('');
  const [postRetirementYears, setPostRetirementYears] = useState('');
  const [result, setResult] = useState('');

  // Debugging logs to check input values
  console.log(currentExpenses, inflationRate, yearsToRetirement, monthlyInvestment, postRetirementYears);

  // Function to calculate future retirement cost and check if monthly investment is enough
  const calculateRetirementPlan = () => {
    const E = parseFloat(currentExpenses);  // Current monthly expenses
    const r = parseFloat(inflationRate) / 100;  // Inflation rate in decimal
    const n = parseFloat(yearsToRetirement);  // Years until retirement
    const monthlySIP = parseFloat(monthlyInvestment);  // User's monthly investment
    const retirementYears = parseFloat(postRetirementYears); // Number of years post-retirement

    // Check for invalid inputs
    if (isNaN(E) || isNaN(r) || isNaN(n) || isNaN(monthlySIP) || isNaN(retirementYears) || E <= 0 || r <= 0 || n <= 0 || monthlySIP <= 0 || retirementYears <= 0) {
      setResult('Please enter valid inputs.');
      return;
    }

    // Calculate future monthly expenses considering inflation
    const futureMonthlyExpenses = E * Math.pow(1 + r, n);

    // Calculate the total amount required for retirement (for post-retirement years)
    const totalRequiredAmount = futureMonthlyExpenses * 12 * retirementYears;

    // Calculate the future value of the user's monthly investment (compound interest formula)
    const futureValueOfSIP = monthlySIP * ((Math.pow(1 + r / 12, n * 12) - 1) / (r / 12));

    // Check if the user's monthly investment is enough to meet the required amount
    const isSIPEnough = futureValueOfSIP >= totalRequiredAmount;

    setResult(`
      Estimated Future Monthly Expenses at Retirement: ₹${futureMonthlyExpenses.toFixed(2)}
      Total Amount Needed for Retirement (for ${retirementYears} years): ₹${totalRequiredAmount.toFixed(2)}.
      Monthly SIP Investment Needed: ₹${(totalRequiredAmount / (n * 12)).toFixed(2)}.
      Your SIP Future Value: ₹${futureValueOfSIP.toFixed(2)}
      ${isSIPEnough ? 'Your current monthly SIP is enough to meet the goal!' : 'Your current monthly SIP is not enough. Consider increasing your investment.'}
    `);
  };

  return (
    <div className="calculator">
      <h1>Retirement Planning Calculator</h1>
      <div className="form-group">
        <label htmlFor="currentExpenses">Current Monthly Expenses (₹):</label>
        <input
          type="number"
          id="currentExpenses"
          value={currentExpenses}
          onChange={(e) => setCurrentExpenses(e.target.value)}
          placeholder="Enter current monthly expenses"
        />
      </div>
      <div className="form-group">
        <label htmlFor="inflationRate">Annual Inflation Rate (%):</label>
        <input
          type="number"
          id="inflationRate"
          value={inflationRate}
          onChange={(e) => setInflationRate(e.target.value)}
          placeholder="Enter inflation rate"
        />
      </div>
      <div className="form-group">
        <label htmlFor="yearsToRetirement">Number of Years Until Retirement:</label>
        <input
          type="number"
          id="yearsToRetirement"
          value={yearsToRetirement}
          onChange={(e) => setYearsToRetirement(e.target.value)}
          placeholder="Enter number of years"
        />
      </div>
      <div className="form-group">
        <label htmlFor="monthlyInvestment">Monthly SIP Investment (₹):</label>
        <input
          type="number"
          id="monthlyInvestment"
          value={monthlyInvestment}
          onChange={(e) => setMonthlyInvestment(e.target.value)}
          placeholder="Enter your monthly SIP"
        />
      </div>
      <div className="form-group">
        <label htmlFor="postRetirementYears">Post-Retirement Period (Years):</label>
        <input
          type="number"
          id="postRetirementYears"
          value={postRetirementYears}
          onChange={(e) => setPostRetirementYears(e.target.value)}
          placeholder="Enter number of post-retirement years"
        />
      </div>
      <button className="btn" onClick={calculateRetirementPlan}>Calculate</button>
      <div className="result">{result}</div>
    </div>
  );
};

export default RetirementCalculator;
