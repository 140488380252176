import React, { useState } from 'react';
import '../styles/Calculator.css';

const ChildEducationCalculator = () => {
  const [currentCost, setCurrentCost] = useState('');
  const [inflationRate, setInflationRate] = useState('');
  const [yearsToGoal, setYearsToGoal] = useState('');
  const [monthlyInvestment, setMonthlyInvestment] = useState('');
  const [result, setResult] = useState('');

  // Debugging logs to check input values
  console.log(currentCost, inflationRate, yearsToGoal, monthlyInvestment);

  // Function to calculate future education cost and check monthly investment sufficiency
  const calculateFutureCost = () => {
    const C = parseFloat(currentCost);  // Current cost of education (annual)
    const r = parseFloat(inflationRate) / 100;  // Inflation rate in decimal
    const n = parseFloat(yearsToGoal);  // Years until education is needed
    const monthlySIP = parseFloat(monthlyInvestment);  // User's monthly investment

    // Debugging log to check parsed values
    console.log('C:', C, 'r:', r, 'n:', n, 'monthlySIP:', monthlySIP);

    // Check for invalid inputs
    if (isNaN(C) || isNaN(r) || isNaN(n) || isNaN(monthlySIP) || C <= 0 || r <= 0 || n <= 0 || monthlySIP <= 0) {
      setResult('Please enter valid inputs.');
      return;
    }

    // Calculate future cost considering inflation
    const futureCost = C * Math.pow(1 + r, n);
    const totalRequiredAmount = futureCost * n; // Total amount needed to cover education for all years

    // Calculate the future value of the user's monthly investment (compound interest formula)
    const futureValueOfSIP = monthlySIP * ((Math.pow(1 + r / 12, n * 12) - 1) / (r / 12));

    // Calculate if the user's SIP is enough
    const isSIPEnough = futureValueOfSIP >= totalRequiredAmount;

    setResult(`
      Estimated Future Cost of Education: ₹${futureCost.toFixed(2)} per year
      Total Amount Needed: ₹${totalRequiredAmount.toFixed(2)} for all years.
      Monthly SIP Investment Needed: ₹${(totalRequiredAmount / (n * 12)).toFixed(2)}.
      Your SIP Future Value: ₹${futureValueOfSIP.toFixed(2)}
      ${isSIPEnough ? 'Your current monthly SIP is enough to meet the goal!' : 'Your current monthly SIP is not enough. Consider increasing your investment.'}
    `);
  };

  return (
    
    <div className="calculator">
      <h1>Child Education Calculator</h1>
      <div className="form-group">
        <label htmlFor="currentCost">Current Annual Cost of Education (₹):</label>
        <input
          type="number"
          id="currentCost"
          value={currentCost}
          onChange={(e) => setCurrentCost(e.target.value)}
          placeholder="Enter current annual cost"
        />
      </div>
      <div className="form-group">
        <label htmlFor="inflationRate">Annual Inflation Rate (%):</label>
        <input
          type="number"
          id="inflationRate"
          value={inflationRate}
          onChange={(e) => setInflationRate(e.target.value)}
          placeholder="Enter inflation rate"
        />
      </div>
      <div className="form-group">
        <label htmlFor="yearsToGoal">Number of Years Until Education:</label>
        <input
          type="number"
          id="yearsToGoal"
          value={yearsToGoal}
          onChange={(e) => setYearsToGoal(e.target.value)}
          placeholder="Enter number of years"
        />
      </div>
      <div className="form-group">
        <label htmlFor="monthlyInvestment">Monthly SIP Investment (₹):</label>
        <input
          type="number"
          id="monthlyInvestment"
          value={monthlyInvestment}
          onChange={(e) => setMonthlyInvestment(e.target.value)}
          placeholder="Enter your monthly SIP"
        />
      </div>
      <button className="btn" onClick={calculateFutureCost}>Calculate</button>
      <div className="result">{result}</div>
    </div>
    
  
  );
};

export default ChildEducationCalculator;
