import React from 'react';
import '../styles/Hero.css';

const Hero = () => {
  // Sample data for cards (You can modify these as needed)
  const cardsData = [
    {
      title: 'Financial Planning',
      description: 'Develop realistic plans by addressing funds weaknesses',
      icon:require("../assets/financepalning.png") ,
    },
    {
      title: 'Wealth Management',
      description: 'Guarantees rolling your money till the period of retirement.',
      icon:require("../assets/wealth.jpg"),
    },
    {
      title: 'Project Funding',
      description: 'Put your plans into actions through monitoring its progress regularly.',
      icon:require("../assets/project-funding.jpg"),
    },
   
  ];

  return (
    <section className="hero" id="home">
      {/* Hero Section */}
      <div className="hero-content">
        <h1>Hegde F P</h1>
        <p>we help you managing asset, Provide financial advise. Leave money issues with us and focus on your core business.</p>
      </div>

      <h1 className='heading'>We Offer Great
      Number of Finance Solutions</h1>

      {/* Cards Section */}
      <div className="cards-section">
        
        {cardsData.map((card, index) => (
          <div key={index} className="card">
            <img src={card.icon} alt={card.title} className="card-icon" />
            <h3>{card.title}</h3>
            <p>{card.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Hero;
