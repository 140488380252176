import React, { useState } from 'react';
import '../styles/Calculator.css';

const LumpsumCalculator = () => {
  const [lumpsum, setLumpsum] = useState('');
  const [annualReturn, setAnnualReturn] = useState('');
  const [investmentDuration, setInvestmentDuration] = useState('');
  const [result, setResult] = useState('');

  // Debugging logs to check input values
  console.log(lumpsum);

  const calculateLumpsum = () => {
    const P = parseFloat(lumpsum); // Lumpsum amount (Principal)
    const r = parseFloat(annualReturn) / 100; // Annual rate converted to decimal
    const n = parseFloat(investmentDuration); // Duration in years

    // Debugging log to check parsed values
    console.log('P:', P, 'r:', r, 'n:', n);

    // Check for invalid inputs
    if (isNaN(P) || isNaN(r) || isNaN(n) || P <= 0 || r <= 0 || n <= 0) {
      setResult('Please enter valid inputs.');
      return;
    }

    // Lumpsum formula: A = P * (1 + r) ^ n
    const maturityAmount = P * Math.pow(1 + r, n);
    setResult(`Maturity Amount: ₹${maturityAmount.toFixed(2)}`);
  };

  return (
    <div className="calculator">
      <h1>Lumpsum Calculator</h1>
      <div className="form-group">
        <label htmlFor="lumpsum">Lumpsum Investment (₹):</label>
        <input
          type="number"
          id="lumpsum"
          value={lumpsum}
          onChange={(e) => setLumpsum(e.target.value)}
          placeholder="Enter amount"
        />
      </div>
      <div className="form-group">
        <label htmlFor="annualReturn">Expected Annual Return Rate (%):</label>
        <input
          type="number"
          id="annualReturn"
          value={annualReturn}
          onChange={(e) => setAnnualReturn(e.target.value)}
          placeholder="Enter rate"
        />
      </div>
      <div className="form-group">
        <label htmlFor="investmentDuration">Investment Duration (years):</label>
        <input
          type="number"
          id="investmentDuration"
          value={investmentDuration}
          onChange={(e) => setInvestmentDuration(e.target.value)}
          placeholder="Enter years"
        />
      </div>
      <button className="btn" onClick={calculateLumpsum}>Calculate</button>
      <div className="result">{result}</div>
    </div>
  );
};

export default LumpsumCalculator;
