import React, { useState } from 'react';
import '../styles/Calculator.css';

const SIPCalculator = () => {
  const [monthlyInvestment, setMonthlyInvestment] = useState('');
  const [annualReturn, setAnnualReturn] = useState('');
  const [investmentDuration, setInvestmentDuration] = useState('');
  const [result, setResult] = useState('');


  console.log(monthlyInvestment)
  const calculateSIP = () => {
    const P = parseFloat(monthlyInvestment);
    const r = parseFloat(annualReturn) / 100 / 12; // Monthly rate
    const n = parseFloat(investmentDuration) * 12; // Total months

    console.log('P:', P, 'r:', r, 'n:', n);


    // Check for invalid inputs
    if (isNaN(P) || isNaN(r) || isNaN(n) || P <= 0 || r <= 0 || n <= 0) {
      setResult('Please enter valid inputs.');
      return;
    }

    // SIP formula
    const maturityAmount = P * ((Math.pow(1 + r, n) - 1) / r) * (1 + r);
    setResult(`Maturity Amount: ₹${maturityAmount.toFixed(2)}`);
  };

  return (
    <div className="calculator">
      <h1>SIP Calculator</h1>
      <div className="form-group">
        <label htmlFor="monthlyInvestment">Monthly Investment (₹):</label>
        <input
          type="number"
          id="monthlyInvestment"
          value={monthlyInvestment}
          onChange={(e) => setMonthlyInvestment(e.target.value)}
          placeholder="Enter amount"
        />
      </div>
      <div className="form-group">
        <label htmlFor="annualReturn">Expected Annual Return Rate (%):</label>
        <input
          type="number"
          id="annualReturn"
          value={annualReturn}
          onChange={(e) => setAnnualReturn(e.target.value)}
          placeholder="Enter rate"
        />
      </div>
      <div className="form-group">
        <label htmlFor="investmentDuration">Investment Duration (years):</label>
        <input
          type="number"
          id="investmentDuration"
          value={investmentDuration}
          onChange={(e) => setInvestmentDuration(e.target.value)}
          placeholder="Enter years"
        />
      </div>
      <button className="btn" onClick={calculateSIP}>Calculate</button>
      <div className="result" id="result">{result}</div>
    </div>
  );
};

export default SIPCalculator;
