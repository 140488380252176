import React, { useState } from 'react';
import '../styles/Facilities.css';
import menus from '../servicesData';

const Facilities = () => {
  const [activeMenu, setActiveMenu] = useState(null);
  const [activeSubMenuContent, setActiveSubMenuContent] = useState("");

 

  const handleMenuClick = (menu) => {
    setActiveMenu((prevMenu) => (prevMenu === menu ? null : menu));
  };

  const handleSubMenuClick = (content) => {
    setActiveSubMenuContent(content);
    setActiveMenu(null); // Close the dropdown after a selection
  };

  return (
    <section className="facilities">
      <div className='services-content'>
      <h1>Our Services</h1>
      </div>
      

      {/* Main Menu */}
      <div className="menu-container">
        {Object.keys(menus).map((menu) => (
          <div
            key={menu}
            className="menu-item"
            onClick={() => setActiveMenu(menu)}
            onMouseLeave={() => setActiveMenu(null)}
          >
            <h2 onClick={() => handleMenuClick(menu)}>{menu}</h2>
            {activeMenu === menu && (
              <ul className="dropdown-menu">
                {Object.entries(menus[menu]).map(([submenu, content]) => (
                  <li
                    key={submenu}
                    onClick={() => handleSubMenuClick(content)}
                    className="submenu-item"
                  >
                    {submenu}
                  </li>
                ))}
              </ul>
            )}
          </div>
        ))}
      </div>

      {/* Content Display */}
      <div className="content-display">
        {activeSubMenuContent ? (
        
          <p>{activeSubMenuContent}</p>
        ) : (
          <div>
          <p>SIP, also known as Regular Savings Plan (RSP) in some countries, allows you to invest a fixed amount
             at pre-defined frequencies in mutual funds. A bank or post office recurring deposit is the only other
              investment option that is similar to SIP. There are basically two options that an investor could take
              when they are making investments, one would be to invest lump sum into mutual funds and the other would
               be to invest using an SIP.</p><br/>
          <h3>Systematic Investment Plan Benefits</h3>
          <ul>
            <li>Get financial discipline to life</li>
            <li>Start Investing with a small amount</li>
            <li>Stay invested irrespective of market conditions</li>
            <li>Cost averaging brings down purchase cost</li>
            <li>Enhance possibilities of better returns</li>
          </ul><br/>
          
          <h3>Our Investment Offers</h3>
          <ul>
            <li>Get financial discipline to life</li>
            <li>Start Investing with a small amount</li>
            <li>Stay invested irrespective of market conditions</li>
            <li>Cost averaging brings down purchase cost</li>
            <li>Enhance possibilities of better returns</li>
          </ul>
          </div>
        )}
      </div>
    </section>
  );
};

export default Facilities;
