// src/components/ProductsPage.js
import React, { useState } from 'react';
import resources from '../resourcedata';
import '../styles/Resource.css';
import SIPCalculator from '../pages/SIPCalculator';

const ResourcesPage = () => {
  const [activeMenu, setActiveMenu] = useState(null);
  const [activeSubMenuContent, setActiveSubMenuContent] = useState("");

  const handleMenuClick = (menu, content) => {
    // Check if the menu has submenus (content is an object) or direct JSX content
    if (typeof content !== 'object' || React.isValidElement(content)) {
      // Set the content directly for menus with no submenus
      setActiveSubMenuContent(content);
      setActiveMenu(null); // Close the dropdown
    } else {
      // Toggle the dropdown menu
      setActiveMenu((prevMenu) => (prevMenu === menu ? null : menu));
    }
  };

  const handleSubMenuClick = (content) => {
    setActiveSubMenuContent(content);
    setActiveMenu(null); // Close the dropdown after a selection
  };

  return (
    <section className="resources">
      <div className="resources-content">
        <h1>Resources</h1>
      </div>

      {/* Main Menu */}
      <div className="menu-container">
        {Object.keys(resources).map((menu) => {
          const menuContent = resources[menu];

          return (
            <div
              key={menu}
              className="menu-item1"
              onClick={() => setActiveMenu(menu)}
              onMouseLeave={() => setActiveMenu(null)}
            >
              <h2 onClick={() => handleMenuClick(menu, menuContent)}>{menu}</h2>
              {activeMenu === menu && typeof menuContent === 'object' && !React.isValidElement(menuContent) && (
                <ul className="dropdown-menu">
                  {Object.entries(menuContent).map(([submenu, content]) => (
                    <li
                      key={submenu}
                      onClick={() => handleSubMenuClick(content)}
                      className="submenu-item"
                    >
                      {submenu}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          );
        })}
      </div>

      {/* Content Display */}
      <div className="content-display1">
        {activeSubMenuContent ? (
          <div>{activeSubMenuContent}</div>
        ) : (
         <SIPCalculator/>
        )}
      </div>
    </section>
  );
};

export default ResourcesPage;
