import React, { useState } from 'react';
import '../styles/Calculator.css';

const ChildMarriageCalculator = () => {
  const [currentCost, setCurrentCost] = useState('');
  const [inflationRate, setInflationRate] = useState('');
  const [yearsToGoal, setYearsToGoal] = useState('');
  const [result, setResult] = useState('');

  // Debugging logs to check input values
  console.log(currentCost, inflationRate, yearsToGoal);

  // Function to calculate future marriage cost
  const calculateFutureCost = () => {
    const C = parseFloat(currentCost); // Current cost of marriage
    const r = parseFloat(inflationRate) / 100; // Inflation rate in decimal
    const n = parseFloat(yearsToGoal); // Years until marriage

    // Check for invalid inputs
    if (isNaN(C) || isNaN(r) || isNaN(n) || C <= 0 || r <= 0 || n <= 0) {
      setResult('Please enter valid inputs.');
      return;
    }

    // Calculate future cost considering inflation
    const futureCost = C * Math.pow(1 + r, n);
    const monthlySIP = futureCost / (12 * n);

    setResult(`Estimated Future Cost of Marriage: ₹${futureCost.toFixed(2)}
    Monthly SIP Investment Needed: ₹${monthlySIP.toFixed(2)}`);
  };

  return (
   
      <div className="calculator">
        <h1>Child Marriage Planning Calculator</h1>
        <div className="form-group">
          <label htmlFor="currentCost">Current Cost of Marriage (₹):</label>
          <input
            type="number"
            id="currentCost"
            value={currentCost}
            onChange={(e) => setCurrentCost(e.target.value)}
            placeholder="Enter current cost of marriage"
          />
        </div>
        <div className="form-group">
          <label htmlFor="inflationRate">Annual Inflation Rate (%):</label>
          <input
            type="number"
            id="inflationRate"
            value={inflationRate}
            onChange={(e) => setInflationRate(e.target.value)}
            placeholder="Enter inflation rate"
          />
        </div>
        <div className="form-group">
          <label htmlFor="yearsToGoal">Number of Years Until Marriage:</label>
          <input
            type="number"
            id="yearsToGoal"
            value={yearsToGoal}
            onChange={(e) => setYearsToGoal(e.target.value)}
            placeholder="Enter number of years"
          />
        </div>
        <button className="btn" onClick={calculateFutureCost}>Calculate</button>
        <div className="result">{result}</div>
    </div>
    
  );
};

export default ChildMarriageCalculator;
